import { Box, Container, Grid, makeStyles, Typography } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import FormTextField from "../../../FormTextField"
import addClubIcon from "../../../../../images/plus_add_club.svg"
import line from "../../../../../images/line.svg"
import NormalSelect from "../../../SelectWithImage/NormalSelect"
import clubInfoImg from "../../../../../images/clubInfoimg.svg"
import SelectWithIcon from "../../../SelectWithImage/SelectWithIcon"
import AddCourtItems from "./AddCourtItems"
import { useSelector, useDispatch } from "react-redux"
import { actions } from "../../../../../redux/slices/clubSlice"
import { fetchCountryCurrencies } from "../../../../../utils/countryAndCodes"
import {
  SUPPORTED_SPORTS,
  formatSportsName,
} from "../../../../../utils/racqy-helper"
import {
  DATA_STATUS,
  DATA_BOOKING,
  DATA_OUTIN,
  DATA_SPORT,
} from "../../../../../utils/constants"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "transprent",
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    height: "100%",
    paddingTop: "10px",
    paddingLeft: "21px",
    fontFamily: "DM Sans",
  },
  titleText: {
    fontSize: "28px",
    marginBottom: "40px",
    fontWeight: "400",
    fontStyle: "normal",
  },
  DivSelect: {
    display: "flex",
    width: "100%",
  },
}))
const DATA_SPORTS = SUPPORTED_SPORTS.map(x => {
  return { label: x[0].toUpperCase() + x.slice(1), value: x }
})
function AddCourtForm({ validation, errors, setFormsErrors }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const courtState = useSelector(state => state.clubReducer.courtInfo)
  const [currencies, setCurrencies] = useState([])
  const [formFields, setFormFields] = useState([])

  const addFields = () => {
    addCourt()
    setFormFields([...formFields, "dummy"])
  }

  const removeFields = index => {
    let data = [...formFields]
    data.splice(index, 1)
    setFormFields(data)
  }

  const handleChange = (key, value) => {
    let temp = { ...errors.courts }
    let tempValue = { ...temp[0] }
    delete tempValue[key]
    temp[0] = tempValue
    setFormsErrors({ ...errors, courts: temp })
    dispatch(
      actions.courtInfo({ key1: "courtInfo", key2: key, value, index: 0 })
    )
  }

  const addCourt = () => {
    dispatch(actions.addCourt())
  }

  const removeCourt = index => {
    dispatch(actions.removeCourt({ index }))
  }

  useEffect(() => {
    if (courtState.length > 1) {
      setFormFields(Array(courtState.length - 1).fill("dummy"))
    }
  }, [courtState])

  useEffect(() => {
    fetchCountryCurrencies().then(options => {
      setCurrencies(options)
    })
  }, [])

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" style={{ marginLeft: 22 }}>
        <Typography className={classes.titleText}>2. Add products</Typography>
        <Box style={{ display: "flex" }}>
          <div style={{ width: "541px" }}>
            <Grid item container>
              <Grid md={12}>
                <div style={{ width: "100%" }}>
                  {" "}
                  <FormTextField
                    errors={errors["courts"]["0"]?.courtName}
                    onChange={e => handleChange("courtName", e.target.value)}
                    value={courtState[0]?.courtName}
                    placeHolder="Court 1"
                    label="Product Name"
                  />
                </div>
                <div className={classes.DivSelect}>
                  <div style={{ width: "48.3%", marginRight: "5px" }}>
                    <NormalSelect
                      errors={errors["courts"]["0"]?.sports}
                      validation={validation}
                      onChange={value => handleChange("sports", value)}
                      value={courtState[0]?.sports}
                      data={DATA_SPORTS}
                      placeHolder="Select Sport"
                      label="Category"
                    />
                  </div>
                  <div style={{ width: "48.3%", marginLeft: "5px" }}>
                    <NormalSelect
                      errors={errors["courts"]["0"]?.courtType}
                      validation={validation}
                      data={DATA_OUTIN}
                      onChange={value => handleChange("courtType", value)}
                      value={courtState[0]?.courtType}
                      placeHolder="Outside"
                      label="Outside/Inside"
                    />
                  </div>
                </div>
                <div className={classes.DivSelect}>
                  <div style={{ width: "48.3%", margin: "10px 5px 0px 0px" }}>
                    <NormalSelect
                      errors={errors["courts"][0]?.status}
                      validation={validation}
                      onChange={value => handleChange("status", value)}
                      value={DATA_STATUS.find(
                        item => item.label === courtState[0]?.status?.label
                      )}
                      data={DATA_STATUS}
                      placeHolder="Status"
                      label="Status"
                    />
                  </div>
                  <div style={{ width: "48.3%", margin: "10px 0px 0px 5px" }}>
                    <NormalSelect
                      currencies={currencies}
                      errors={errors["courts"][0]?.bookingLength}
                      validation={validation}
                      onChange={value => handleChange("bookingLength", value)}
                      value={courtState[0]?.bookingLength}
                      data={DATA_BOOKING}
                      placeHolder="Booking length"
                      label="Booking length"
                    />
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <Grid style={{ width: "60%" }}>
                    <SelectWithIcon
                      label="Price"
                      placeholder="currency"
                      value={courtState[0]?.currency}
                      options={currencies}
                      errors={errors["courts"][0]?.currency}
                      onChange={e => handleChange("currency", e)}
                      getOptionLabel={option => (
                        <div
                          style={{
                            padding: "7px",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <img
                            src={option.icon}
                            alt="country flag"
                            style={{ width: "20px", height: "10px" }}
                          />
                          <span
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            {option.name}
                          </span>
                        </div>
                      )}
                      getOptionValue={option => option.name}
                      components={{
                        Option: props => (
                          <div
                            {...props.innerProps}
                            style={{
                              padding: "7px",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <img
                              src={props.data.icon}
                              alt="country flag"
                              style={{ width: "30px", height: "20px" }}
                            />
                            <span
                              style={{
                                marginLeft: "10px",
                              }}
                            >
                              {props.data.label}
                            </span>
                          </div>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid style={{ width: "100%", minWidth: "60%" }}>
                    <FormTextField
                      errors={errors["courts"][0]?.price}
                      onChange={e => handleChange("price", e.target.value)}
                      label=""
                      placeHolder="price"
                      value={courtState[0]?.price}
                    />
                  </Grid>
                </div>
                <Grid md={12}>
                  {formFields.map((value, index) => {
                    return (
                      <div key={value}>
                        <AddCourtItems
                          currencies={currencies}
                          errors={errors}
                          index={index + 1}
                          courtInfo={[...courtState]}
                          onValueChange={key => {
                            let temp = { ...errors.courts }
                            let tempValue = { ...temp[index + 1] }
                            delete tempValue[key]
                            temp[index + 1] = tempValue
                            setFormsErrors({ ...errors, courts: temp })
                          }}
                          validation={validation}
                          handleDeleteClick={() => {
                            removeFields(value)
                            removeCourt(index + 1)

                            let temp = { ...errors.courts }
                            delete temp[index + 1]

                            setFormsErrors({ ...errors, courts: temp })
                          }}
                        />
                      </div>
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div style={{ width: "53%" }}>
            <img
              src={clubInfoImg}
              width="300px"
              style={{
                right: 100,
                top: 224,
                position: "absolute",
              }}
              alt=""
            />
          </div>
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            float: "left",
            marginLeft: "12px",
          }}
        >
          <Typography style={{ fontFamily: "DM Sans", fontSize: "15px" }}>
            Add Product{" "}
          </Typography>
          <img
            src={addClubIcon}
            onClick={addFields}
            alt="add"
            style={{ marginLeft: "5px", cursor: "pointer" }}
          />
          <br />
        </div>
        <br />
        {/* image line here */}
      </Container>
    </div>
  )
}

export default AddCourtForm
